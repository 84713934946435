<template>
  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients section-bg pt-5 pb-5">
    <div class="container" data-aos="zoom-in">
      <div class="section-title">
        <!-- <h2>{{ $t('lang.certifications.title') }}</h2> -->
        <h3 v-html="translate('lang.clients.title')"></h3>
      </div>
      <carousel
        class="clients-carousel"
        data-aos="zoom-in"
        :autoplay="true"
        :nav="false"
        :dots="false"
        :items="1"
        :loop="true"
        :responsive="{0:{items:1},786:{items:2},992:{items:3}}"
      >
        <div
          class="col-lg-12 d-flex align-items-center justify-content-center"
          v-for="client of [1, 2, 3, 4, 5, 6]"
          :key="client"
        >
          <img
            :src="require(`../assets/img/clients/${client}.png`)"
            class="img-fluid"
            alt=""
          />
        </div>
      </carousel>
      
      <!-- <div class="row">

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            v-for="client of [1,2,3,4,5,6]" :key="client">
            <img :src="require(`../assets/img/clients/${client}.png`)" class="img-fluid" alt="">
          </div>
        </div> -->
    </div>
  </section>
  <!-- End Clients Section -->
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: { carousel },
  name: "ClientsComponent",
  props: {},
  methods: {
    translate(text) {
      return this.$t(text);
    },
  },
  data() {
    return {
      clients: [],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
