import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en'
import es from './locales/es'

Vue.use(VueI18n)

export default new VueI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages: {
        es: {
            lang: es
        },
        en: {
            lang: en
        }
    }
})