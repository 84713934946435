import VueRouter from "vue-router"
import HomePage from "../pages/Home"
import AboutUsPage from "../pages/AboutUs"
import ContactPage from "../pages/Contact"
import WarrantyPage from "../pages/Warranty"
import CityWaterPage from "../pages/CityWater"
import WellWaterPage from "../pages/WellWater"

const routes = [
  { path: '/', name: "home", component: HomePage },
  { path: '/about-us', name: "about-us", component: AboutUsPage },
  { path: '/contact', name: "contact", component: ContactPage },
  { path: '/warranty', name: "warranty", component: WarrantyPage },
  {
    path: '/product-city-water',
    name: 'CityWater',
    component: CityWaterPage
  },
  {
    path: '/product-well-water',
    name: 'WellWater',
    component: WellWaterPage
  }
]

const publicPath = process.env.NODE_ENV === 'production' ? '/' : '/muestras/water-home-solutions/';

const router = new VueRouter({
  base: publicPath,
  mode: 'history',
  routes // short for `routes: routes`
})

export default router;