import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=3f979ecc&scoped=true&"
import script from "./Testimonials.vue?vue&type=script&lang=js&"
export * from "./Testimonials.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f979ecc",
  null
  
)

export default component.exports