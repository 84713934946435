<template>
    <section id="hero">
        <div class="hero-container">
            <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

                <ol class="carousel-indicators" id="hero-carousel-indicators">
                    <li data-target="#heroCarousel" data-slide-to="0" class="active"></li>
                    <li data-target="#heroCarousel" data-slide-to="1" class=""></li>
                    <li data-target="#heroCarousel" data-slide-to="2" class=""></li>
                </ol>

                <div class="carousel-inner" role="listbox">

                    <!-- Slide 1 -->
                    <div v-for="(slide, index) in slides" :key='slide._id'
                         class="carousel-item"
                         v-bind:class="{ active:index == 0 }"
                         :style="'background-image: url(' + slide.background + ')'">
                        <div class="carousel-container">
                            <div class="carousel-content">
                                <h2 data-aos="fade-in-out" data-aos-delay="100" v-html="slide.title"></h2>
                                <p data-aos="fade-in-out" data-aos-delay="100">{{slide.description}}</p>
                                <a :href="slide.link" class="btn-get-started scrollto" :class="'bg-'+slide.button.color">
                                    <i :class="slide.button.icon" v-if="slide.button.icon"></i>
                                    {{ slide.button.text }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>

                <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                    <span class="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>

            </div>
        </div>
        <!--    </vue-aos>-->
    </section><!-- End Hero -->
</template>

<script>
    export default {
        name: 'HeroComponent',
        props: {
            parent: {},
        },
        data() {
            return {
                slides: [
                    {
                        title: '<span>' + this.$t('lang.slides.1.title') + '</span>',
                        description: this.$t('lang.slides.1.description'),
                        background: require('../assets/img/grifo-perdiendo-agua.webp'),
                        button: {
                            icon: 'icofont-brand-whatsapp',
                            text: this.$t('lang.slides.1.button'),
                            color: 'success',
                        },
                        link: `https://wa.me/${this.parent.phoneWhatsAppLink(this.parent.phone)}?text=Hola quiero mas información.`,
                    },
                    {
                        title: '<span>' + this.$t('lang.slides.2.title') + '</span>',
                        description: this.$t('lang.slides.2.description'),
                        background: require('../assets/img/water-better-skin.webp'),
                        button: {
                            icon: null,
                            text: this.$t('lang.slides.2.button'),
                            color: null,
                        },
                        link: '/contact',
                    },
                    {
                        title: '<span>' + this.$t('lang.slides.3.title') + '</span>',
                        description: this.$t('lang.slides.3.description'),
                        background: require('../assets/img/water_quality.webp'),
                        button: {
                            icon: null,
                            text: this.$t('lang.slides.3.button'),
                            color: null,
                        },
                        link: '/contact',
                    },
                ]
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
