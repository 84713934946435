<template>
    <div>
        <!-- ======= Top Bar ======= -->
        <div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
            <div class="container d-flex">
                <div class="contact-info mr-auto">
                    <i class="icofont-envelope"></i> <a
                        href="mailto:info@aquaworld-systems.com">{{$parent.email}}</a>
                    <i class="icofont-phone"></i> <a 
                    :href="`https://wa.me/${$parent.phoneWhatsAppLink($parent.phone)}?text=Hola quiero mas información.`" target="_blank"> {{$parent.phone}}</a>
                </div>
                <div class="social-links">
                    <a v-for="social of $parent.socials" :key='social._id'
                    :href="social.link"
                    target="_blank"
                    :class="social.name"><i :class="'icofont-' + social.icon"></i></a>
                </div>
            </div>
        </div>
        <!-- ======= Header ======= -->
        <header id="header" class="fixed-top">
            <div class="container d-flex align-items-center">

                <!-- <h1 class="logo mr-auto"><a href="/">World Money Transfer<span>.</span></a></h1> -->
                <!-- Uncomment below if you prefer to use an image logo -->
                <a href="/" class="logo mr-auto"><img src="../assets/img/logo.png" alt=""></a>

                <nav id="navbar" class="nav-menu d-none d-lg-block">
                    <ul>
                        <li :class="{ active: $route.path == '/'}"><router-link to="/">{{ $t("lang.menu.home") }}</router-link></li>
                        <li :class="{ active: $route.path == '/about-us'}"><router-link to="/about-us">{{ $t("lang.menu.about-us") }}</router-link></li>
                        <li :class="{ active: $route.path == '/contact'}"><router-link to="/contact">{{ $t("lang.menu.contact") }}</router-link></li>
                        <li class="drop-down d-none d-lg-block">
                            <a href="#">{{$t("lang.menu.products")}}</a>
                            <ul>
                                <li>
                                    <router-link :to="{name: 'CityWater'}">{{ $t("lang.products.city-water.name") }}</router-link>
                                    <router-link :to="{name: 'WellWater'}">{{ $t("lang.products.well-water.name") }}</router-link>
                                </li>
                            </ul>
                        </li>
                        <li :class="{ active: $route.path == '/warranty'}"><router-link to="/warranty">{{ $t("lang.menu.warranty") }}</router-link></li>
                        <li class="drop-down d-none d-lg-block">
                            <a href="#"><img :src="viewLng()" width="20" style="vertical-align: middle;"/></a>
                            <ul>
                                <li v-for="lang in langs" :key='lang._id'>
                                    <a href="#" v-on:click.prevent="changeLng(lang.lang)"><img :src="lang.icon" width="20"
                                                                                    style="vertical-align: middle;"/>
                                        {{$t("lang.langs." + lang.lang)}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav><!-- .nav-menu -->
                <nav id="navbar_lang" class="nav-menu d-lg-none">
                    <ul>
                        <li class="drop-down">
                            <a href="#"><img :src="viewLng()" width="20" style="vertical-align: middle;"/></a>
                            <ul>
                                <li v-for="lang in langs" :key='lang._id'>
                                    <a href="#" v-on:click.prevent="changeLng(lang.lang)"><img :src="lang.icon" width="20"
                                                                                    style="vertical-align: middle;"/>
                                        {{$t("lang.langs." + lang.lang)}}</a>
                                </li>
                            </ul>
                        </li>
                        <li class="mobile-nav-toggle d-lg-none">
                            <i class="icofont-navigation-menu"></i>
                        </li>
                    </ul>
                </nav>
                <div class="mobile-nav-overly"><i class="icofont-close"></i></div>
                <nav id="navbar_mobile" class="mobile-nav d-lg-none">
                    <ul>
                        <li :class="{ active: $route.path == '/'}"><router-link to="/">{{ $t("lang.menu.home") }}</router-link></li>
                        <li :class="{ active: $route.path == '/about-us'}"><router-link to="/about-us">{{ $t("lang.menu.about-us") }}</router-link></li>
                        <li :class="{ active: $route.path == '/contact'}"><router-link to="/contact">{{ $t("lang.menu.contact") }}</router-link></li>
                        <li class="drop-down">
                            <a class="title">{{$t("lang.menu.products")}}</a>
                            <ul>
                                <li>
                                    <router-link :to="{name: 'CityWater'}">{{ $t("lang.products.city-water.name") }}</router-link>
                                    <router-link :to="{name: 'WellWater'}">{{ $t("lang.products.well-water.name") }}</router-link>
                                </li>
                            </ul>
                        </li>
                        <li :class="{ active: $route.path == '/warranty'}"><router-link to="/warranty">{{ $t("lang.menu.warranty") }}</router-link></li>
                    </ul>
                </nav><!-- .nav-menu -->

            </div>
        </header><!-- End Header -->
    </div>
</template>

<script>
    import jQuery from 'jquery';

    export default {
        name: 'HeaderComponent',
        props: {},
        computed:{
            routeName(){
                return this.$route.name
            },
        },
        methods: {
            viewLng: function () {
                for (let lang of this.$data.langs) {
                    if (lang.lang == this.$i18n.locale)
                        return lang.icon;
                }
            },
            changeLng: function (lang) {
                this.$i18n.locale = lang;
            }
        },
        data() {
            return {
                langs: [
                    {"lang": "es", "icon": require('../assets/img/lang/es.svg')},
                    {"lang": "en", "icon": require('../assets/img/lang/en.svg')},
                ]
            }
        },
        created() {
            const $ = jQuery;
            // Mobile Navigation
            //if ($('#navbar').length) {
            $(document).ready(function () {

                $('.nav-menu li > a').on('click', function(){
                    $(window).scrollTop(0);
                })

                $(document).on('click', '.mobile-nav-toggle', function () {
                    $('body').toggleClass('mobile-nav-active');
                    $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                    $('.mobile-nav-overly').toggle();
                });

                $(".mobile-nav ul li a").on('click', function () {
                    if ($('body').hasClass('mobile-nav-active') && !$(this).hasClass('title')) {
                        $('body').removeClass('mobile-nav-active');
                        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                        $('.mobile-nav-overly').fadeOut();
                    }
                });

                $('.mobile-nav .drop-down > .title').on('click', function (e) {
                    e.preventDefault();
                    $(this).next().slideToggle(300);
                    $(this).parent().toggleClass('active');
                });

                $(document).click(function (e) {
                    let container = $(".mobile-nav, .mobile-nav-toggle");
                    if (!container.is(e.target) && container.has(e.target).length === 0) {
                        if ($('body').hasClass('mobile-nav-active')) {
                            $('body').removeClass('mobile-nav-active');
                            $('.mobile-nav-overly').fadeOut();
                        }
                    }
                });
            });
        }
    }
</script>

<style scoped>

</style>
