import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import 'jquery'
import 'jquery.easing'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/style.scss'
import i18n from './lang/i18n'
import router from './routes/route'

Vue.use(VueRouter)

 /* eslint-disable no-new */
 new Vue({
    el: '#app',
    i18n,
    router,
    template: '<App/>',
    components: { App }
  })
