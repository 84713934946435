<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>{{ $t("lang.contact.title") }}</h2>
        <h3 v-html="translate('lang.contact.description')"></h3>
        <!--        <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae autem.</p>-->
      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="100">
        <div class="col-lg-6">
          <div class="info-box mb-4">
            <i class="bx bx-map"></i>
            <h3>{{ $t("lang.contact.address") }}</h3>
            <p>{{ parent.address }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box mb-4">
            <i class="bx bx-envelope"></i>
            <h3>{{ $t("lang.contact.email") }}</h3>
            <p>{{ parent.email }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box mb-4">
            <i class="bx bx-phone-call"></i>
            <h3>{{ $t("lang.contact.phone") }}</h3>
            <p>{{ parent.phone }}</p>
          </div>
        </div>
      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="100">
        <div class="col-lg-6">
          <!-- <iframe class="mb-4 mb-lg-0"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2739.485636231711!2d8.525853435268303!3d47.377445980326094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a1a06d499c9%3A0x803f99245d2fd71b!2sHohlstrasse%2047%2C%208004%20Z%C3%BCrich%2C%20Suiza!5e0!3m2!1ses!2sar!4v1610028960163!5m2!1ses!2sar"
                            frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe> -->
          <div class="map">
            <img src="../assets/img/map.png" class="img-fluid" />
          </div>
        </div>

        <div class="col-lg-6">
          <form
            action="http://waterhome-solutions.com/forms/contact.php"
            method="post"
            role="form"
            class="php-email-form"
          >
            <div class="form-row">
              <div class="col form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  :placeholder="translate('lang.contact.placeholders.name')"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col form-group">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  :placeholder="translate('lang.contact.placeholders.email')"
                  data-rule="email"
                  data-msg="Please enter a valid email"
                />
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                name="subject"
                id="subject"
                :placeholder="translate('lang.contact.placeholders.subject')"
                data-rule="minlen:4"
                data-msg="Please enter at least 8 chars of subject"
              />
              <div class="validate"></div>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                data-rule="required"
                data-msg="Please write something for us"
                :placeholder="translate('lang.contact.placeholders.message')"
              ></textarea>
              <div class="validate"></div>
            </div>
            <div class="mb-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                {{ $t("lang.contact.message.success") }}
              </div>
            </div>
            <div class="text-center">
              <button type="submit">{{ $t("lang.contact.button") }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Section -->
</template>

<script>
//Jquery Import
import jQuery from "jquery";

export default {
  name: "ContactComponent",
  props: {
    parent: {},
  },
  methods: {
    translate(text) {
      return this.$t(text);
    },
  },
  created() {
    const $ = jQuery;
    $(document).ready(function () {
      $("form.php-email-form").submit(function (e) {
        e.preventDefault();

        var f = $(this).find(".form-group"),
          ferror = false,
          emailExp = /^[^\s()<>@,;:]+@\w[\w.-]+\.[a-z]{2,}$/i;

        f.children("input").each(function () {
          // run all inputs

          var i = $(this); // current input
          var rule = i.attr("data-rule");

          if (rule !== undefined) {
            var ierror = false; // error flag for current input
            var pos = rule.indexOf(":", 0);
            if (pos >= 0) {
              var exp = rule.substr(pos + 1, rule.length);
              rule = rule.substr(0, pos);
            } else {
              rule = rule.substr(pos + 1, rule.length);
            }

            switch (rule) {
              case "required":
                if (i.val() === "") {
                  ferror = ierror = true;
                }
                break;

              case "minlen":
                if (i.val().length < parseInt(exp)) {
                  ferror = ierror = true;
                }
                break;

              case "email":
                if (!emailExp.test(i.val())) {
                  ferror = ierror = true;
                }
                break;

              case "checked":
                if (!i.is(":checked")) {
                  ferror = ierror = true;
                }
                break;

              case "regexp":
                exp = new RegExp(exp);
                if (!exp.test(i.val())) {
                  ferror = ierror = true;
                }
                break;
            }
            i.next(".validate")
              .html(
                ierror
                  ? i.attr("data-msg") !== undefined
                    ? i.attr("data-msg")
                    : "wrong Input"
                  : ""
              )
              .show("blind");
          }
        });
        f.children("textarea").each(function () {
          // run all inputs

          var i = $(this); // current input
          var rule = i.attr("data-rule");

          if (rule !== undefined) {
            var ierror = false; // error flag for current input
            var pos = rule.indexOf(":", 0);
            if (pos >= 0) {
              var exp = rule.substr(pos + 1, rule.length);
              rule = rule.substr(0, pos);
            } else {
              rule = rule.substr(pos + 1, rule.length);
            }

            switch (rule) {
              case "required":
                if (i.val() === "") {
                  ferror = ierror = true;
                }
                break;

              case "minlen":
                if (i.val().length < parseInt(exp)) {
                  ferror = ierror = true;
                }
                break;
            }
            i.next(".validate")
              .html(
                ierror
                  ? i.attr("data-msg") != undefined
                    ? i.attr("data-msg")
                    : "wrong Input"
                  : ""
              )
              .show("blind");
          }
        });
        if (ferror) return false;

        var this_form = $(this);
        var action = $(this).attr("action");

        if (!action) {
          this_form.find(".loading").slideUp();
          this_form
            .find(".error-message")
            .slideDown()
            .html("The form action property is not set!");
          return false;
        }

        this_form.find(".sent-message").slideUp();
        this_form.find(".error-message").slideUp();
        this_form.find(".loading").slideDown();

        if ($(this).data("recaptcha-site-key")) {
          // var recaptcha_site_key = $(this).data('recaptcha-site-key');
          // grecaptcha.ready(function () {
          //     grecaptcha.execute(recaptcha_site_key, {action: 'php_email_form_submit'}).then(function (token) {
          //         php_email_form_submit(this_form, action, this_form.serialize() + '&recaptcha-response=' + token);
          //     });
          // });
        } else {
          php_email_form_submit(this_form, action, this_form.serialize());
        }

        return true;
      });

      function php_email_form_submit(this_form, action, data) {
        $.ajax({
          type: "POST",
          url: action,
          data: data,
          dataType: "json",
          timeout: 40000,
        })
          .done(function (msg) {
            if (msg.status && msg.status === 'success') {
              this_form.find(".loading").slideUp();
              this_form.find(".sent-message").slideDown();
              this_form.find("input:not(input[type=submit]), textarea").val("");
            } else {
              this_form.find(".loading").slideUp();
              if (!msg) {
                msg =
                  "Form submission failed and no error message returned from: " +
                  action +
                  "<br>";
              }
              this_form.find(".error-message").slideDown().html(msg);
            }
          })
          .fail(function (data) {
            var error_msg = "Form submission failed!<br>";
            if (data.statusText || data.status) {
              error_msg += "Status:";
              if (data.statusText) {
                error_msg += " " + data.statusText;
              }
              if (data.status) {
                error_msg += " " + data.status;
              }
              error_msg += "<br>";
            }
            if (data.responseText) {
              error_msg += data.responseText;
            }
            this_form.find(".loading").slideUp();
            this_form.find(".error-message").slideDown().html(error_msg);
          });
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
