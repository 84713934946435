<template>
    <div id="app">
        <HeaderComponent/>
        <router-view router-view></router-view>
        <FooterComponent/>

        <div id="preloader"></div>
        <div class="buttons-bottom-action">
            <a :href="`https://wa.me/${this.phoneWhatsAppLink(phone)}?text=Hola quiero mas información.`" target="_blank" class="whatsApp">
            <i class="icofont-brand-whatsapp"></i> <span  data-aos="open-in"><strong>CONTACTANOS AHORA!</strong></span></a>
            <a href="#" class="back-to-top" v-on:click="backToTop"><i class="icofont-simple-up"></i></a>
        </div>
    </div>

</template>


<script>
    // Modules Import
    import HeaderComponent from './components/Header.vue'
    import FooterComponent from './components/Footer.vue'
    //AOS Import
    import AOS from 'aos';
    import 'aos/dist/aos.css'

    //Jquery Import
    import jQuery from 'jquery';

    //JS external Import
    require('./assets/js/main');

    export default {
        name: 'App',
        components: {
            HeaderComponent,
            FooterComponent,
            //Service
        },
        data() {
            return {
                socials: [
                    { link: "https://www.facebook.com/waterhomesolutions", icon: "facebook", name: "facebook"},
                    { link: "https://www.instagram.com/waterhomesolutions/", icon: "instagram", name: "instagram"},
                    // { link: "https://twitter.com/", icon: "twitter", name: "twitter"},
                    // { link: "#", icon: "skype", name: "skype"},
                    // { link: "#", icon: "linkedin", name: "linkedin"},
                ],
                address: '1000 Heritage Center Cir, Round Rock, TX 78664',
                country: "United State",
                email: "info@waterhome-solutions.com",
                url: "http://www.waterhome-solutions.com/",
                companyName: "Water Home Solutions",
                phone: "+1 888-7840318"
            }
        },
        methods: {
            backToTop: function () {
                jQuery('html, body').animate({
                    scrollTop: 0
                }, 1500, 'easeInOutExpo');
                return false;
            },
            phoneWhatsAppLink: (phone) => {
                return phone.replace(/([()\\ s-])?/g, '');
            }
        },
        created() {
            const $ = jQuery;
            $(document).ready(function () {
                $('#preloader').delay(100).fadeOut('slow', function () {
                    $(this).remove();
                });

                //Init AOS
                AOS.init({
                    duration: 1200,
                    once: true
                });

                if ($(window).scrollTop() > 100) {
                    $('#header').addClass('header-scrolled');
                    $('#topbar').addClass('topbar-scrolled');
                }

                // Back to top button
                $(window).on('scroll', function() {
                    if ($(this).scrollTop() > 100) {
                    $('.buttons-bottom-action').addClass('scrolling');
                    $('#header').addClass('header-scrolled');
                    $('#topbar').addClass('topbar-scrolled');
                    } else {
                    $('.buttons-bottom-action').removeClass('scrolling');
                    $('#header').removeClass('header-scrolled');
                    $('#topbar').removeClass('topbar-scrolled');
                    }
                });

                $(document).on('click', '.scrollto', function(e) {
                    var scrolltoOffset = $('#header').outerHeight() - 21;
                    if (window.matchMedia("(max-width: 991px)").matches) {
                        scrolltoOffset += 20;
                    }
                    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    if (target.length) {
                        e.preventDefault();

                        var scrollto = target.offset().top - scrolltoOffset;

                        if ($(this).attr("href") == '#header') {
                        scrollto = 0;
                        }

                        $('html, body').animate({
                        scrollTop: scrollto
                        }, 1500, 'easeInOutExpo');

                        return false;
                    }
                    }
                });
            });
        },
    }
</script>

<style>
    @import "./assets/vendor/boxicons/css/boxicons.min.css";
    @import "./assets/vendor/icofont/icofont.min.css";
    /*@import "./assets/css/style.css";*/
</style>