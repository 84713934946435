<template>
    <div>
        <HeroComponent :parent="this.$parent"/>
        <main id="main">
            <FeatureComponent/>
            <section id="products" class="about section-bg">
                <div class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>{{ $t('lang.products.title') }}</h2>
                        <h3 v-html="$t('lang.products.message')"></h3>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-4 text-center">
                            <img src="../assets/img/products/city-water/1.jpg" class="img-fluid" />
                            <h4 class="mt-2"><router-link to="/product-city-water">{{ $t('lang.products.city-water.name') }}</router-link></h4>
                        </div>
                        <div class="col-md-4 text-center">
                            <img src="../assets/img/products/well-water/1.jpg" class="img-fluid" />
                            <h4 class="mt-2"><router-link to="/product-well-water">{{ $t('lang.products.well-water.name') }}</router-link></h4>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" class="about">
                <div class="container" data-aos="fade-up">
                    <div class="section-title">
                        <p><img src="../assets/img/logo_only.png" style="max-width: 300px;"></p>
                        <h3 class="mt-5">{{ $t("lang.company.message") }}</h3>
                    </div>
                </div>
            </section>
            <TestimonialsComponent/>
            <CertificatesComponent></CertificatesComponent>
            <ClientsComponent></ClientsComponent>
            <ContactComponent :parent="$parent"></ContactComponent>
        </main>
    </div>
</template>

<script>
    // Modules Import
    import HeroComponent from '../components/Hero'
    //import Service from './components/Service'
    import FeatureComponent from '../components/Feature'
    //import TeamComponent from '../components/Team'
    import TestimonialsComponent from '../components/Testimonials'
    import CertificatesComponent from '../components/Certificates'
    import ClientsComponent from '../components/Clients'
    import ContactComponent from '../components/Contact'
    export default {
        name: 'HomePage',
        components: {
            HeroComponent,
            FeatureComponent,
            CertificatesComponent,
            //TeamComponent,
            TestimonialsComponent,
            ClientsComponent,
            ContactComponent,
            //Service
        },
    }
</script>


<style>

</style>