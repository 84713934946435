<template>
  <section id="featured-services" class="featured-services">
    <div class="container" data-aos="fade-up">

      <div class="row">
        <div v-for="feature in features" :key='feature._id' class="col-md-4 d-flex align-items-stretch mb-5 text-center">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <!-- <div class="icon"><i class="bx bx-money"></i></div> -->
            <img :src="require(`../assets/img/benefits/${feature.img}`)" :alt="`feature ${feature._id}`">
            <h4 class="title"><a href="">{{ $t(`lang.benefits[${feature.id}].title`) }}</a></h4>
            <p class="description">{{ $t(`lang.benefits[${feature.id}].description`) }}</p>
          </div>
        </div>
      </div>
      <hr/>
      <div class="section-title mt-4">
          <h3 v-html="$t('lang.benefits2.title')"></h3>
          <div class="mt-4">El agua alcalina tiene un nivel de pH más alto que el agua corriente. Es por eso que los defensores dicen que puede neutralizar el ácido en el torrente sanguíneo. Algunos dicen que el agua alcalina puede ayudar a prevenir enfermedades, como el cáncer y las enfermedades cardíacas.</div>
          <img :src="require('@/assets/img/benefits/water-alcaline.jpeg')" alt="Agua Alcalina - Water alkaline" class="mt-4 mb-4" style="max-width:500px">
          <h4>Estos son algunos de sus Beneficios:</h4>
      </div>
      <div class="row">
        <div v-for="benefit2 in benefits2" :key='benefit2._id' class="col-md-4 d-flex align-items-stretch mb-5 text-center">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i :class="benefit2.icon"></i></div>
            <h4 class="title"><a href="">{{ $t(`lang.benefits2.boxes[${benefit2.id}].title`) }}</a></h4>
            <p class="description">{{ $t(`lang.benefits2.boxes[${benefit2.id}].description`) }}</p>
          </div>
        </div>
        <img src="@/assets/img/benefits/tabla-ahorro.jpg">
      </div>

    </div>
  </section><!-- End Featured Services Section -->
</template>

<script>
  //import VueAos from 'vue-aos'

  export default {
    //components: { VueAos },
    name: 'FeatureComponent',
    props: {},
    data() {
      return {
          features: [
              {id: 0, img:'water_good.webp'},
              {id: 1, img:'better_skin.webp' },
              {id: 2, img:'better_hair.webp' },
          ],
          benefits2: [
              {id: 0, icon:'bx bx-droplet'},
              {id: 1, icon:'bx bx-heart' },
              {id: 2, icon:'bx bx-donate-heart' },
          ]
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
