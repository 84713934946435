<template>
    <div>
        <!-- ======= Hero Section ======= -->
        <section id="hero" class="d-flex align-items-center simple-page" style="pointer-events: none;">
            <div class="image-background">
                <img src="../assets/img/water_glass_clean.jpg" alt="background water video">
                <div class="video-overlay"></div>
            </div>
            <vue-aos
                class="container"
                animation-class="zoom-out animated"
                data-aos-delay="100"
            >
                <div class="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>{{ $t("lang.warranty-and-maintenance.title") }}</h1>
                </div>
            </vue-aos>
        </section>
        <main id="main">
            <section id="about" class="about section-bg">
                <div class="container" data-aos="fade-up">
                    <!-- <div class="section-title">
                        <h2>{{ $t("lang.warranty-and-maintenance.title") }}</h2>
                    </div> -->
                    <div class="row">
                        <div class="col-md-5">
                            <img
                                src="../assets/img/warranty/puronics-warranty.jpeg"
                                class="img-fluid"
                                alt=""
                            />
                        </div>
                        <div class="col-md-7">
                            <h3>{{ $t("lang.warranty-and-maintenance.warranty.title") }}</h3>
                            <p>{{ $t("lang.warranty-and-maintenance.warranty.description") }}</p>
                            <h3>{{ $t("lang.warranty-and-maintenance.maintenance.title") }}</h3>
                            <p>{{ $t("lang.warranty-and-maintenance.maintenance.description") }}</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>

    export default {
        name: 'WarrantyPage',
        components: {
        },
        created() {
            // const $ = jQuery;
            // $(document).ready(function () {
            // });
        },
    }
</script>


<style>

</style>