<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center simple-page">
      <div class="image-background">
        <img src="../assets/img/water_glass_clean.jpg" alt="background water video" />
        <div class="video-overlay"></div>
      </div>
      <!-- <div class="video-background">
            <div class="video-foreground">
              <video autoplay="" loop="" class="fillWidth fadeIn animated" 
                    poster="../assets/img/water-video.png" id="video-background">
                  <source src="../assets/img/water-video.mp4" type="video/mp4">
              </video>
              <div class="video-overlay"></div>
            </div>
        </div> -->
      <vue-aos
        class="container"
        animation-class="zoom-out animated"
        data-aos-delay="100"
      >
        <div class="container" data-aos="zoom-out" data-aos-delay="100">
          <h1>Sobre Nosotros</h1>
          <h2>
            Contamos con 6 años en el mercado y <br />20.000 Clientes
            satisfechos
          </h2>
          <div class="d-flex">
            <router-link to="contact" class="btn-get-started">{{
              $t("lang.menu.contact")
            }}</router-link>
            <!-- <a
              href="https://www.youtube.com/watch?v=B5jW8qA-uMM"
              target="_blank"
              class="venobox btn-watch-video"
              data-vbtype="video"
              data-autoplay="true"
            >
              Mira un video de nosotros <i class="icofont-play-alt-2"></i
            ></a> -->
          </div>
        </div>
      </vue-aos>
    </section>
    <!-- End Hero -->
    <main id="main">
      <section id="featured-services" class="featured-services">
        <div class="container" data-aos="fade-up">
          <div class="row justify-content-center">
            <div
              class="
                col-sm-12 col-lg-10
                d-flex
                align-items-stretch
                mb-5
                text-center
              "
            >
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon"><i class="bx bx-question-mark"></i></div>
                <h4 class="title"><a href="">Como funciona</a></h4>
                <p class="description text-left">
                  Primeramente, un análisis de su agua el cual consta de varios
                  procesos sea para aguas de ciudad o pozo, en la cual
                  determinamos que tipo de sistema de tratamiento necesita para
                  poder brindar costos, explicar mantenimientos y como es todo
                  el proceso.<br /><br />
                  Brindamos financiamiento Para personas con uso de ITT Number o
                  Seguro Social para cualquiera de los casos de agua de ciudad o
                  pozo. <br /><br />
                  Nuestros programas que ofrecemos a nuestros clientes vienen
                  acompañado de garantías tanto de los sistemas como de la
                  instalación adicional nuestros planes son con $0 de
                  Downpayment, 0$ Fee instalación, 0$ TAX. Brindándole a
                  nuestros clientes la mayor facilidad de cambiar su calidad de
                  agua de manera casi inmediata.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Featured Services Section -->
      <!-- Start About Section -->
      <section id="about" class="about section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Productos de calidad</h2>
            <h3>Somos dealer autorizado de <span>Puronics</span></h3>
          </div>

          <div class="row">
            <div class="col-lg-6" data-aos="zoom-out" data-aos-delay="100">
              <img
                src="../assets/img/hydronex-c-igen-12.19.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="
                col-lg-6
                pt-4 pt-lg-0
                content
                d-flex
                flex-column
                justify-content-center
              "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3>Puronics brinda:</h3>
              <ul class="icon-sm">
                <li v-for="item of [0, 1, 2, 3, 4, 5, 6]" :key="item._id">
                  <i class="bx bx-check"></i>
                  <div class="pt-2">
                    <h5>{{ $t(`lang.about-us.puronics[${item}]`) }}</h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="
                col-lg-2 col-md-3 col-6
                d-flex
                align-items-center
                justify-content-center
              "
              v-for="cert of certificates"
              :key="cert.id"
            >
              <img
                :src="cert.image"
                class="img-fluid"
                alt=""
                style="max-width: 100px"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- End About Section -->
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>{{ $t("lang.about-us.location") }}</h2>
            <h3 v-html="translate('lang.about-us.our-building')"></h3>
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-4">
              <div class="info-box mb-4">
                <i class="bx bx-map"></i>
                <h3>{{ $t("lang.contact.address") }}</h3>
                <p>{{ $parent.address }}</p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="info-box mb-4">
                <i class="bx bx-envelope"></i>
                <h3>{{ $t("lang.contact.email") }}</h3>
                <p>{{ $parent.email }}</p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="info-box mb-4">
                <i class="bx bx-phone-call"></i>
                <h3>{{ $t("lang.contact.phone") }}</h3>
                <p>
                  <a
                    :href="
                      'https://wa.me/' +
                      $parent.phoneWhatsAppLink($parent.phone)
                    "
                    target="_blank"
                  >
                    {{ $parent.phone }}</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="mb-4 col-12">
              <div class="info-box">
                <h4>Encuéntranos a nivel internacional</h4>
                <div class="map-box">
                  <div class="places">
                    <div class="place-list">
                      <ul>
                        <li v-for="place of places" :key="place">
                          <i class="bx bx-map"></i> {{ place }}
                        </li>
                      </ul>
                    </div>
                    <div class="place-list">
                      <ul>
                        <li v-for="place2 of places2" :key="place2">
                          <i class="bx bx-map"></i> {{ place2 }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="map">
                    <img src="../assets/img/map.png" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Contact Section -->
    </main>
  </div>
</template>

<script>
export default {
  name: "AboutUsPage",
  components: {
    //Service
  },
  methods: {
    translate(text) {
      return this.$t(text);
    },
  },
  data() {
    return {
      places: [
        "Texas",
        "Florida",
        "California",
        "Washington",
        "Nevada",
        "Arizona",
        "New York",
        "New Jersey",
        "Illinois",
      ],
      places2: [
        "Utah",
        "Colorado",
        "Pensilvania",
        "Meryland",
        "Georgia",
        "North Carolina",
        "Tennessee",
        "Indiana",
      ],
      certificates: [
        {
          name: "Nasa",
          image: require("../assets/img/certs/nasa-logo-150x150.png"),
        },
        {
          name: "Asociación de Calidad del Agua",
          image: require("../assets/img/certs/logo-water-quality-association-gold-seal-640.png"),
        },
        {
          name: "Agencia de Protección Ambiental",
          image: require("../assets/img/certs/EPAseal-150x150.png"),
        },
        {
          name: "Better Business Bureau A+",
          image: require("../assets/img/certs/ASAP-BBB-A.png"),
        },
      ],
    };
  },
  created() {
    // const $ = jQuery;
    // $(document).ready(function () {
    // });
  },
};
</script>

<style>
</style>