<template>
      <section class="products-details">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5 products-details-container">
              <carousel class="products-details-carousel" data-aos="zoom-in" :autoplay="true" :nav="false" :dots="true" :items="1" :loop="true">
                <img :src="require(`@/assets/img/products/${products[product].type}/${img}`)" v-for="img of products[product].imgs" :key="img.id" class="img-fluid" alt="">
              </carousel>

              <!-- <div class="products-info">
                <h3>Project information</h3>
                <ul>
                  <li><strong>Category</strong>: Web design</li>
                  <li><strong>Client</strong>: ASU Company</li>
                  <li><strong>Project date</strong>: 01 March, 2020</li>
                  <li><strong>Project URL</strong>: <a href="#">www.example.com</a></li>
                </ul>
              </div> -->

            </div>
            <div class="col-md-7 products-description">
              <h2>{{products[product].name}}</h2>
              <p v-html="products[product].description"></p>
              <a :href="`https://wa.me/${parent.phoneWhatsAppLink(parent.phone)}?text=${products[product].wsp_message}`" 
                target="_blank"
                class="btn btn-primary btn-get-started">Adquirir producto</a>
            </div>
        </div>
      </div>
    </section><!-- End Portfolio Details Section -->
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  components: { carousel },
  name: 'ProductsComponent',
  props: {
    product: String,
    parent: {},
  },
  data(){
    return {
      products: {
        cityWater: {
          type: 'city-water',
          name: this.$t('lang.products.city-water.name'),
          description: this.$t('lang.products.city-water.description'),
          wsp_message: 'Quiero comprar Agua de ciudad',
          imgs: ['1.jpg','2.jpg','3.jpg','4.jpg','5.jpg']
        },
        wellWater: {
          type: 'well-water',
          name: "Agua de Pozo",
          description: this.$t('lang.products.well-water.description'),
          wsp_message: 'Quiero comprar Agua de pozo',
          imgs: ['1.jpg','2.jpg','3.jpg','4.jpg','5.jpg','6.jpg','7.jpg','8.jpg','9.jpg','10.jpg']
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
