<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">

    <div class="footer-top">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-xl-4 col-md-6 footer-contact">
            <img src="../assets/img/logo.png" style="width: 100%; padding: 0 20px 10px 0">
            <p>
              {{$parent.address}},<br>
              {{$parent.country}}<br><br>
              <strong>{{ $t('lang.footer.phone')}}:</strong> {{$parent.phone}}<br>
              <strong>{{ $t('lang.footer.email')}}:</strong> {{$parent.email}}<br>
            </p>
          </div>


          <div class="d-none d-md-block col-lg-4 col-xl-3 col-md-6 footer-links">
            <h4>{{ $t('lang.footer.links') }}</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <router-link :to="{name: 'CityWater'}" v-on:click="scrollTop()">{{ $t('lang.products.city-water.name') }}</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link :to="{name: 'WellWater'}" v-on:click="scrollTop()">{{ $t('lang.products.well-water.name') }}</router-link></li>
            </ul>
          </div>

          <div class="col-lg-12 col-xl-3 col-md-12  text-center">
            <h4>{{ $t('lang.footer.social-media') }}</h4>
            <div class="social-links mt-3">
              <a v-for="social of $parent.socials" :key='social._id'
                 :href="social.link"
                 target="_blank"
                 :class="social.name"><i :class="'bx bxl-'+social.icon"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container py-4">
      <div class="copyright">
        &copy; Copyright 2020 <strong><span>{{$parent.companyName}}</span></strong>. {{$t('lang.footer.copyright')}}
      </div>
      <div class="credits">
<!--        Designed by <a href="/">Sintony</a>-->
      </div>
    </div>
  </footer>
  <!-- End Footer -->
</template>

<script>
  export default {
    name: 'FooterComponent',
    methods: {
      scrollTop: function () {
        window.scrollTo(0)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
