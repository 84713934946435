<template>
  <!-- ======= Team Section ======= -->
  <section id="certifications" class="certifications">
    <div class="container" data-aos="slide-left">

      <div class="section-title">
        <h2>{{ $t('lang.certifications.title') }}</h2>
        <h3 v-html="translate('lang.certifications.description')"></h3>
      </div>

      <div class="row">

        <div  v-for="cert in certificates" :key='cert._id'
              class="col-lg-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
          <div class="certification">
            <div class="certification-img text-center mt-3">
              <img :src="cert.image" class="img-fluid" alt="">
            </div>
            <div class="certification-info">
              <h4>{{$t(`lang.certifications.certs.${cert.type}.title`)}}</h4>
              <p>{{$t(`lang.certifications.certs.${cert.type}.description`)}}</p>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section><!-- End Team Section -->
</template>

<script>
export default {
  name: 'CertificatesComponent',
  props: {},
  methods: {
    translate(text){
      return this.$t(text);
    }
  },
  data() {
    return {
      certificates : [
        {
          "type": "nasa",
          "image" : require('../assets/img/certs/nasa-logo-150x150.png'),
        },
        {
          "type": "water-quality",
          "image" : require('../assets/img/certs/logo-water-quality-association-gold-seal-640.png'),
        },
        {
          "type": "epa",
          "image" : require('../assets/img/certs/EPAseal-150x150.png'),
        },
        {
          "type" : "bbb",
          "image" : require('../assets/img/certs/ASAP-BBB-A.png'),
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
