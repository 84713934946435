<template>
    <div>
        <!-- ======= Hero Section ======= -->
        <section id="hero" class="d-flex align-items-center simple-page" style="pointer-events: none;">
            <div class="image-background">
                <img src="../assets/img/water_glass_clean.jpg" alt="background water video">
                <div class="video-overlay"></div>
            </div>
            <vue-aos
                class="container"
                animation-class="zoom-out animated"
                data-aos-delay="100"
            >
                <div class="container" data-aos="zoom-out" data-aos-delay="100">
                    <h3>Producto</h3>
                    <h1>{{$t('lang.products.well-water.name')}}</h1>
                </div>
            </vue-aos>
        </section>
        <main id="main">
            <ProductsComponent :parent="$parent" product="wellWater"></ProductsComponent>
        </main>
    </div>
</template>

<script>
    // Modules Import
    import ProductsComponent from '../components/Products.vue'

    export default {
        name: 'WellWaterPage',
        components: {
            ProductsComponent,
        },
        created() {
            // const $ = jQuery;
            // $(document).ready(function () {
            // });
        },
    }
</script>


<style>

</style>