<template>
    <!-- ======= Testimonials Section ======= -->
    <section id="testimonials" class="testimonials">
      <div class="container" >

        <div class="section-title">
          <h2>{{ $t('lang.testimonials.title') }}</h2>
          <h3 v-html="translate('lang.testimonials.description')"></h3>
        </div>

        <carousel class="testimonials-carousel" data-aos="zoom-in" :autoplay="true" :nav="false" :dots="true" :items="1" :loop="true">
          <div v-for="testimonial of testimonials" :key='testimonial._id' class="testimonial-item" >
              <img :src="require(`../assets/img/testimonials/${testimonial.id}.jpg`)" class="testimonial-img" alt="">
              <h3>{{testimonial.name}}</h3>
              <!-- <h4>{{testimonial.position}}</h4> -->
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                {{testimonial.message}}
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
        </carousel>
      </div>
    </section>
    <!-- End Testimonials Section -->
</template>

<script>
  //Jquery Import
  //import jQuery from 'jquery';
  import carousel from 'vue-owl-carousel'

  export default {
    components: { carousel },
    name: 'TestimonialsComponent',
    props: {},
    methods: {
      translate(text){
        return this.$t(text);
      }
    },
    data() {
      return {
        testimonials: [
          {id: 1, name: "Maria Perez", position: "CEO of XYZ Corp", message: "Water Home Solutions ha sido una excelente Inversion para nuestra casa. Los sistemas de purificación de agua son eficientes y confiables, y el servicio al cliente es excepcional siempre contestan nuestra llamadas"},
          {id: 2, name: "Juan Moya", position: "President of ABC Inc", message: "Desde que instalamos el sistema de purificación de agua de Water Home Solutions, hemos notado una gran mejoría en la calidad del agua que utilizamos en casa y lo profesional de sus técnicos al realizar el trabajo."},
          {id: 3, name: "Delmary Deleon", position: "General Manager of LMN Industries", message: "Water Home Solutions es una empresa confiable y de confianza. El equipo de instalación fue muy  profesional, y el sistema de purificación de agua ha funcionado sin problemas desde entonces mi agua apestaba y ahora estoy encantada limpiecita."},
          {id: 4, name: "Carlos Falcon", position: "Operations Manager of DEF Co", message: "Antes de instalar el sistema de purificación de agua de Water Home Solutions, teníamos problemas con el Olor y color  la calidad del agua del pozo era horrible. Desde entonces, no hemos tenido ningún problema y estamos muy satisfechos con el servicio."},
          {id: 5, name: "Mercedes cinta y Manuel Martinez", position: "Plant Manager of GHI Inc", message: "Water Home Solutions ha resuelto un problema de tantos anos en mi piel donde gastaba dinero en cremas y no solucionaba mi problema de la piel. Su sistema de purificación de agua es de alta calidad y ha hecho que mi piel este sana."},
          {id: 6, name: "Edwin Colon", position: "Facility Manager of JKL Enterprises", message: "Antes de que vinieran a revisar mi agua tenia dudas sobre  el representante de  Water Home Solutions,  creo que fue un bendición contar con mi agua limpia y alcalina con ph de 9.5 para tomar "},
          {id: 7, name: "Felicita Sierra", position: "Facilities Director of MNO Co", message: "El sistema de purificación de agua de Water Home Solutions nos ha traído un ahorro económico y comodidad al no tener que estar cargando a nuestra edad cajas de agua para tomar y bañarnos mi cabello esta mucho mas suave y mi salud ha mejorado."},
          {id: 8, name: "Mariela Valdez", position: "Water Treatment Manager of PQR Inc", message: "De verdad tenias mis dudas que esto pudiera ser algo mas que un producto de venta,  esto ha sido una bendición en mi casa para mi esposo y mis hijos, mis expectativas fueron superadas lo profesional del representante el cambio que hemos tenido en casa para todos fue increíble lo recomendamos el cambio en su calidad de vida es grandioso ."},
          {id: 9, name: "Roberto Sanchez", position: "Water Quality Manager of STU Corp", message: "Compramos una propiedad con agua de pozo y de verdad el agua apestaba fue algo sorpresa para nosotros, pero gracias a Water Home Solutions pudieron solucionar todo y estamos bien contentos con nuestra agua 100% recomendado."},
          {id: 10, name: "Alba y Justo Valle", position: "Water Systems Engineer of VWX Co", message: " Estamos muy contentos con los resultados de nuestro sistema de Tratamiento y de Water Home Solutions, habían visitado otras empresas nos habían ofrecidos productos  hasta mas económico pero investigamos la calidad y procedencia china que tenían  y  No se compara a la calidad del producto hecho en USA  y  a lo profesional de sus representantes y técnicos."}
        ]
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
